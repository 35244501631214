<template>
  <v-container>
    <!-- {{ eventsOrderedbyDateStop }} -->
    <!-- {{ machineStopsActive }} -->
    <v-row>

      <v-col cols="2">
        <v-text-field v-model="startSample" label="Muestra Inicial" readonly></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field v-model="endSample" label="Muestra Final" readonly></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field v-model="min" label="Tiempo Total en Minutos" readonly></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-btn @click="updateOverview()" color="primary" elevation="8" rounded>Actualizar</v-btn>
      </v-col>

      <!-- <v-col cols="2">
        <h5 :style="hbStatus">Heartbeat: {{heartbeat[0].lastSeen.toDate()}}</h5>
      </v-col> -->

      <v-col cols="2">
        <v-btn @click="csv()" color="primary" elevation="8" rounded>CSV</v-btn>
      </v-col>

      <v-col cols="2">
        <v-btn v-if="machineStopsActive == 0" @click="setMachineStopsActive(1)" color="green" elevation="8" rounded class="white--text">ON</v-btn>
        <v-btn v-if="machineStopsActive == 1" @click="setMachineStopsActive(0)" color="red" elevation="8" rounded class="white--text">OFF</v-btn>
      </v-col>

      

      <v-col cols="8">
        <v-img src="../assets/green.png" contain max-height="150" v-if="!activeStop"></v-img>
        <v-img src="../assets/red.png" contain max-height="150" v-if="activeStop"></v-img>
      </v-col>


      <v-col cols="4">
        <h3>Ambiente: {{ new Date(environment.timestamp).toLocaleDateString() }} - {{ new Date(environment.timestamp).toLocaleTimeString() }}</h3>
        <h4>Temperatura: {{ environment.temperature}} &deg;C</h4>
        <h4>Humedad: {{ environment.humidity }} %</h4>
        <h4>Presión: {{ environment.pressure }} Pa</h4>
      </v-col>


      <v-col cols="12">
        <h3 v-if="overview != null">Resumen</h3>
        <v-data-table
          v-if="overview != null"
          dense
          :headers="headers"
          :items="overview"
          :items-per-page="5"
          sort-by="desc"
          class="elevation-1"
          orderBy
        >
          <template v-slot:[`item.stopTotal`]="{ item }">
            <p>{{(item.stopTotal / 60).toFixed(2)}}</p>
          </template>
        
        </v-data-table>
      </v-col>

      <v-col cols="12">

        <!--<v-btn @click="getStops" color="primary" elevation="8" rounded class="mt-3 mb-3">Graficar</v-btn>-->

        <!--<line-chart :data="stops" :height="60" v-if="showGraph"></line-chart>-->
        <h3>Paros de Máquina</h3>
        <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Sensor</th>
              <th class="text-left">Proceso</th>
              <th class="text-left">Hora Paro</th>
              <th class="text-left">Hora Recuperación</th>
              <th class="text-left">Duración de Paro (Seg)</th>
              <th class="text-left">Duración de Paro (Min)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in eventsOrderedbyDateStop" :key="item.key">     
              <td>{{ item.accelerometer }}</td>
              <td>{{ item.accelerometerDesc }}</td>
              <td>{{ new Date(item.dateStop * 1000).toLocaleDateString() }} - {{ new Date(item.dateStop * 1000).toLocaleTimeString() }}</td>
              <td v-if="item.dateReco > 0">{{ new Date(item.dateReco * 1000).toLocaleDateString() }} - {{ new Date(item.dateReco * 1000).toLocaleTimeString() }}</td>
              <td>{{ item.stopDuration  }}</td>
              <td>{{ (item.stopDuration / 60).toFixed(2) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {db} from '../firebase'
import {ref, update} from "firebase/database";
import { mapActions, mapState } from 'vuex'
//import LineChart from '../components/LineChart.vue'

export default {
  name: 'Home',
  data() {
    return {
      startSample: 'Muestra inicial',
      endSample: 'Muestra finial',
      totalSeconds: 0,
      headers: [
        {text: 'Sensor', align: 'start', value: 'desc',},
        {text: 'Proceso', align: 'start', value: 'process',},
        {text: 'Paros Totales', align: 'start', value: 'stops',},
        {text: 'Tiempo acumulado en paros (min)' , align: 'start', value: 'stopTotal',},
        {text: 'Disponibilidad (%)' , align: 'start', value: 'efficiency',},
      ],
      stops: [],
      sensors: [],
      overview: null
      //showGraph: false
    }
  },
  components: {
    //LineChart
  },

  computed: {
    ...mapState(['events','environment','machineStopsActive']),

    min(){
      return (this.totalSeconds / 60).toFixed(2)
    },

    eventsOrderedbyDateStop(){
      return this.events.sort((a,b) => (a.dateStop > b.dateStop) ? -1 : ((b.dateStop < a.dateStop) ? 1 : 0))
      //return this.events.sort()
    },

    /* hbStatus(){
      const hbDate = this.heartbeat[0].lastSeen.toDate()
      const hbSeconds = Math.round(hbDate.getTime() / 1000)
      const nowDate = new Date()
      const nowSeconds = Math.round(nowDate.getTime() / 1000)
      const status = nowSeconds - hbSeconds
      if(status < 900){return "color:green"}
      else{return "color:red"}
    }, */

    activeStop(){
      /* if(this.events.length>0 && !this.events[0].stopDuration){
        return true
      } else {
        return false
      } */
      if(this.events.filter(item => item.dateReco == 0).length > 0){
        return true
      } else {
        return false
      }
    }
    

  },

  methods: {
    ...mapActions(['getEvents','getEnvironment','getMachineStopsActive']),

    updateOverview(){
      const startDate = new Date(2023, 5, 7, 16, 0)
      this.startSample = startDate.toLocaleDateString()+" - "+startDate.toLocaleTimeString()
      const startDateSeconds = Math.round(startDate.getTime() / 1000)

      const endDate = new Date()
      this.endSample = endDate.toLocaleDateString()+" - "+endDate.toLocaleTimeString()
      const endDateSeconds = Math.round(endDate.getTime() / 1000)

      this.totalSeconds = endDateSeconds - startDateSeconds

      this.overview = []
      this.sensors = [...new Set(this.events.map(item => item.accelerometer))]
      this.sensors.forEach(item =>{
        const sensorArray = this.events.filter(e => e.accelerometer == item && e.dateReco != 0)
        const stopTotal = sensorArray.map(e => e.stopDuration).reduce((prev, curr) => prev + curr, 0)
        const efficiency = ((this.totalSeconds - stopTotal) * 100) / this.totalSeconds
        const Obj = {
          desc: sensorArray[0].accelerometer,
          process: sensorArray[0].accelerometerDesc,
          stops: sensorArray.length,
          stopTotal: stopTotal,
          efficiency: efficiency.toFixed(2)
        }
        this.overview.push(Obj)
      })
      //console.log(this.overview)
    },

    csv(){
      this.csvString = [
        [
          "Sensor",
          "Proceso",
          "Fecha Paro",
          "Hora Paro",
          "Fecha Recuperacion",
          "Hora Recuperacion",
          "Duracion Paro",
        ],
        ...this.events.map(item => [
          item.accelerometer,
          item.accelerometerDesc,
          new Date(item.dateStop * 1000).toLocaleDateString(),
          new Date(item.dateStop * 1000).toLocaleTimeString(),
          new Date(item.dateReco * 1000).toLocaleDateString(),
          new Date(item.dateReco * 1000).toLocaleTimeString(),
          item.stopDuration,
        ])
      ].map(e => e.join(","))
      .join("\n")
      //console.log(this.csvString);

      var hiddenElement = document.createElement('a');  
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(this.csvString);  
      hiddenElement.target = '_blank';    
      hiddenElement.download = 'stops.csv';  
      hiddenElement.click();
    },

    setMachineStopsActive(value){
      update(ref(db, 'configuration/machineStops'), {
        active : value
      })
    }
    

  },

  watch: {
    events: function() {
      this.updateOverview()
    }    
  },

  

  async created(){
    this.getMachineStopsActive()
    //await this.getHeartbeat()
    this.getEvents()
    this.getEnvironment()
  },


}
</script>
